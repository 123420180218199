


















import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  private tabs: any = [
    {
      name: "专题学习",
      activePath: "/main/zsyy/yian/yian",
      path: "/main/zsyy/yian/yian-study",
    },
    {
      name: "经验挖掘",
      activePath: "/main/zsyy/yian/jywj",
      path: "/main/zsyy/yian/jywj-folder",
    },
    {
      name: "专题图谱",
      activePath: "/main/zsyy/yian/tupu",
      path: "/main/zsyy/yian/tupu-folder",
    },
    {
      name: "AI辅助决策",
      activePath: "/main/zsyy/yian/fzjc",
      path: "/main/zsyy/yian/fzjc-folder",
    },
    {
      name: "AI虚拟教学",
      activePath: "/main/zsyy/yian/xnjx",
      path: "/main/zsyy/yian/jiaoxue-folder",
    },
  ];
  private get href() {
    return window.location.href;
  }
  private goRoute(item: any) {
    this.$router.push(item.path);
  }
  private get type() {
    return this.$store.state.zhuanti;
  }
}
