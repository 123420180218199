


















import { Component, Vue, Prop } from "vue-property-decorator";
import { GetCategoryList } from "@/request/yian";
@Component({})
export default class Name extends Vue {
  private options: any = [];
  private props: any = {
    value: "id",
    label: "name",
    children: "children",
  };
  private jibing: any = [];
  private get bing() {
    return this.$store.state.jibing;
  }
  private get type() {
    return this.$store.state.zhuanti;
  }
  private change(val: any) {
    let d: any = {};
    this.options.forEach((ele: any) => {
      if (ele.id == val[0]) {
        d.parent = {
          id: ele.id,
          name: ele.name,
        };
        ele.children.forEach((e: any) => {
          if (e.id == val[1]) {
            d.child = e;
          }
        });
      }
    });
    this.$store.commit("updateJibing", d);
  }
  private goBack() {
    this.$router.push("/main/zsyy/yian/folder");
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        search: "",
      },
    };
    GetCategoryList(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.options = res;
      })
      .catch((res: any) => {
        loading.close();
      });
  }
  private mounted() {
    this.getData();
    this.jibing = [this.bing.parent.id, this.bing.child.id];
  }
}
