














































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetFilters } from "@/request/yian";
@Component({
  components: {},
})
export default class Name extends Vue {
  @Prop()
  private filters: any;
  @Watch("filters", { immediate: true })
  private filtersChange() {
    this.data = JSON.parse(JSON.stringify(this.filters));
    this.search("", "症状", "options1");
    this.search("", "中医证型", "options2");
    this.search("", "中医治法", "options3");
    this.search("", "中医方剂", "options4");
    this.search("", "中药饮片", "options5");
    this.search("", "医家", "options6");
    this.search("", "源文献", "options7");
    this.search("", "年代", "options8");
  }
  private data: any = {};
  private options: any = {
    options1: [],
    options2: [],
    options3: [],
    options4: [],
    options5: [],
    options6: [],
    options7: [],
    options8: [],
  };
  private search(val: any, type: any, key: any) {
    const params: any = {
      params: {
        search: val,
        type: type,
      },
    };
    GetFilters(this, params).then((res: any) => {
      this.options[key] = res;
    });
  }
  private change(val: any, type: any) {
    this.$emit("change", this.data);
  }
  private reset() {
    this.data = {
      症状: [],
      中医证型: [],
      中医治法: [],
      中医方剂: [],
      中药饮片: [],
      医家: [],
      源文献: [],
      年代: [],
    };
    this.$emit("change", this.data);
    this.goSearch();
  }
  private goSearch() {
    this.$emit("search");
  }
}
